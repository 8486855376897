<template>
  <ion-fab @click="openModalNewTicket" vertical="bottom" horizontal="end">
    <ion-fab-button>
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <master-layout :pageTitle="$t('pageTitle.report_a_problem')">
    <div v-if="items.length > 0" class="ion-no-padding">
      <ion-item
        @click="openModalChat(item.id)"
        v-for="item in items"
        :key="item.id"
        class="ion-padding-top ion-margin-top"
        style="overflow: visible"
      >
        <ion-row class="ion-justify-content-between">
          <ion-col size="2">
            <ion-icon :icon="chatboxOutline"></ion-icon>
          </ion-col>
          <ion-col size="7" class="ion-text-start">
            {{ item.titolo }}
          </ion-col>
          <ion-col size="2" class="ion-justify-content-end">
            <ion-row>
              <ion-col class="leds">
                <div
                  class="led"
                  :class="{ open: item.status_text == 'Open' }"
                ></div>
                <div
                  class="led"
                  :class="{ 'on-going': item.status_text == 'onGoing' }"
                ></div>
                <div
                  class="led"
                  :class="{ closed: item.status_text == 'Closed' }"
                ></div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-item>
    </div>
    <div v-else style="padding-top: 30px">
      <ion-row class="ion-justify-content-center ion-text-center">
        <ion-col size="auto" class="ion-padding-top">
          <ion-icon
            :icon="alertCircleOutline"
            style="font-size: 3rem; color: #888"
          ></ion-icon>
          <h3 style="color: #888; margin-top: 20px">Non ci sono tickets</h3>
          <p style="color: #888; margin-top: 20px">
            Ottimo! Non ci sono problemi da risolvere.
          </p>
          <p style="color: #888; margin-top: 120px">
            Se riscontri un problema da risolvere, clicca sul pulsante in basso
            a destra per creare un nuovo ticket.
          </p>
        </ion-col>
      </ion-row>
    </div>
  </master-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
} from "@ionic/vue";

import ApiService from "../../common/service.api";
import {
  add,
  chatboxOutline,
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";
import { defineComponent } from "vue";
import ModalChat from "../Modals/ModalChat.vue";
import ModalNewTicket from "../Modals/ModalNewTicket.vue";
import MasterLayout from "../../components/MasterLayout";
export default {
  name: "Report a problem",
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonInput,
    IonSelect,
    IonTextarea,
    IonIcon,
    IonButton,
    IonFab,
    IonFabButton,
    MasterLayout,
  },
  data() {
    return {
      add,
      chatboxOutline,
      checkmarkCircleOutline,
      alertCircleOutline,
      items: [],
    };
  },
  created() {
    this.apiGet();
  },
  methods: {
    apiGet() {
      ApiService.get(
        `HelpDesk/` + this.$store.state.user.userData.id_company
      ).then((res) => {
        res.data.forEach((element) => {
          this.items.push(element);
        });
      });
    },
    modalCloseHandler(item) {
      this.items.forEach((element) => {
        if (element.id == item.ticketId) {
          element.status_text = "Closed";
        }
      });
      this.items.length = 0;
      this.apiGet();
    },
    async openModalChat(idTicket) {
      const modal = await modalController.create({
        component: ModalChat,
        cssClass: "common-modal",
        componentProps: {
          id: idTicket.toString(),
        },
      });
      await modal.present();

      let modalResponse = await modal.onDidDismiss();

      modalResponse.data && this.modalCloseHandler({ ...modalResponse.data });
    },
    async openModalNewTicket() {
      let options = [];
      await ApiService.get(
        "Option/HelpDeskType/" + this.$store.state.user.userData.id_company
      ).then((res) => {
        res.data.forEach((element) => {
          options.push(element);
        });
      });

      const modal = await modalController.create({
        component: ModalNewTicket,
        cssClass: "common-modal",
        componentProps: { options },
      });
      modal.onDidDismiss().then(async () => {
        await ApiService.get(
          `HelpDesk/` + this.$store.state.user.userData.id
        ).then((res) => {
          this.items = [];
          res.data.forEach((element) => {
            this.items.push(element);
          });
        });
      });
      return modal.present();
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  // border: 1px solid rgb(150, 246, 150);
}

ion-row {
  width: 100%;
}

ion-fab-button {
  margin: 1rem;
}

ion-fab-button ion-icon {
  font-size: 2rem;
  color: white;
  margin: 0;
}

ion-icon {
  font-size: 1.5rem;
  color: rgb(141, 141, 141);
}

.leds {
  margin: auto;
  display: flex;
  padding-left: 8px;
}

.led {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0.1rem;
  background: rgb(200, 200, 200);
}

.open {
  background: rgb(231, 36, 36);
}

.on-going {
  background: rgb(255, 169, 40);
}

.closed {
  background: rgb(45, 167, 34);
}
</style>
